import {ReferencingLayout, LogoGreyCircle} from 'common';
import {useStartReplay} from '@/hooks/useStartReplay';
import {useTrackPageView} from '@/hooks/useTrackPageView';
import BrickThemeProvider from '@/themes/BrickThemeProvider';
import {Box, Button, Typography} from '@mui/material';
import lookUnderBed from '@/assets/lookUnderBed.png';
import {StepContent} from '@/pages/BTL/Referencing/StepContent';
import {useIntercom} from 'react-use-intercom';

export const ReferenceSignupServiceUnavailable = () => {
  useStartReplay();
  useTrackPageView();

  const intercom = useIntercom();

  return (
    <BrickThemeProvider>
      <ReferencingLayout>
        <Box sx={{minHeight: '500px'}}>
          <img
            src={lookUnderBed}
            width="100%"
            height={200}
            style={{
              objectFit: 'cover',
              objectPosition: '50% 30%',
            }}
            alt="person looking under bed"
          />
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              marginTop: '-45px',
            }}
          >
            <LogoGreyCircle />
          </Box>
          <StepContent>
            <Typography variant="h1" mt={2}>
              Service No Longer Available
            </Typography>
            <Typography textAlign="center">
              We apologise for any inconvenience, but we are currently pausing
              our referencing services.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              sx={{mt: 4}}
              onClick={() => {
                intercom.trackEvent('Referencing Service Unavailable Contact');
                intercom.showNewMessage('');
              }}
            >
              Contact Support
            </Button>
          </StepContent>
        </Box>
      </ReferencingLayout>
    </BrickThemeProvider>
  );
};
