import Package from '@/assets/cartoons/package.svg';
import PageContainer from '@/components/PageContainer';
import {SideDialogAccordion} from '@/components/dialogs/SideDialog/SideDialogAccordion';
import {
  useHomePackagesQuery,
  usePackageReminderToHomeMutation,
} from '@/generated/graphql';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {edgesToNodes} from '@/utils/edgesToNodes';
import {Inventory} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Skeleton, Stack, Typography} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import {DateTime} from 'luxon';
import {useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import PackageDialog from '../PackageDialog/PackageDialog';
import {CollectAllPackagesDialog} from './CollectAllPackagesDialog/CollectAllPackagesDialog';
import {PackageItem} from './PackageItem';
import {useSnackbar} from 'notistack';
import {uniq} from 'lodash';

export const HomePackagesPage = () => {
  const logEvent = useLogEvent();
  const {palette} = useTheme();
  const navigate = useNavigate();
  const styles = useStyles();
  const {homeId, packageId} = useParams();
  const {state} = useLocation();
  const [collectAllDialogOpen, setCollectAllDialogOpen] = useState(false);
  const [residentsReminded, setResidentsReminded] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const [remindToHome, {loading: reminding}] =
    usePackageReminderToHomeMutation();

  const {data, loading, fetchMore} = useHomePackagesQuery({
    variables: {
      id: homeId as string,
    },
  });

  const totalUncollectedCount =
    data?.home?.uncollectedPackages?.totalCount || 0;

  const totalCollectedCount = data?.home?.collectedPackages?.totalCount || 0;

  const remindResidents = async () => {
    const result = await remindToHome({variables: {input: {id: homeId}}});
    if (result.data?.packageReminderToHome?.home?.id) {
      setResidentsReminded(true);
      enqueueSnackbar('Reminders successfully sent to residents', {
        variant: 'success',
      });
    }
  };

  const otherPickupLocations = uniq(
    edgesToNodes(data?.home?.uncollectedPackages?.edges).map(
      node => node.pickupLocation,
    ),
  ).filter(Boolean);

  return (
    <PageContainer
      backText="Packages"
      handleGoBack={() => {
        if (state?.from) {
          navigate(-1);
        } else {
          navigate('/packages');
        }
      }}
      sx={{pb: {xs: 22, sm: 15}}}
    >
      <Box className={styles.container}>
        <Typography variant="h1" mb={2}>
          {loading ? <Skeleton width="70%" /> : data?.home?.address}
        </Typography>
        <Stack gap={2} mt={4}>
          {otherPickupLocations.length > 0 &&
            otherPickupLocations.map(location => {
              const locationPackages = edgesToNodes(
                data?.home?.uncollectedPackages?.edges,
              ).filter(p => p.pickupLocation === location);
              return (
                <SideDialogAccordion
                  title={`Packages to Collect from ${location}`}
                  startIcon={<Inventory fontSize="small" />}
                >
                  <Stack gap={0.5}>
                    {locationPackages.map(node => {
                      return (
                        <PackageItem
                          id={node.id}
                          addressee={node.addressee || ''}
                          actionDateTime={DateTime.fromISO(node.createdAt)}
                          location={node.location ?? undefined}
                        />
                      );
                    })}
                    {locationPackages.length === 0 && (
                      <Stack alignItems="center">
                        <img
                          src={Package}
                          width="256px"
                          height="256px"
                          alt="package"
                          style={{opacity: 0.4}}
                        />
                        <Typography
                          variant="subtitle1"
                          color={palette.text.secondary}
                          mb={2}
                        >
                          No packages waiting for collection
                        </Typography>
                      </Stack>
                    )}
                    {locationPackages.length > 0 &&
                      locationPackages.length < totalUncollectedCount &&
                      data?.home?.uncollectedPackages?.pageInfo.hasNextPage && (
                        <LoadingButton
                          className={styles.loadingButton}
                          variant="arkOutline"
                          onClick={() => {
                            fetchMore({
                              variables: {
                                uncollectedAfter:
                                  data?.home?.uncollectedPackages?.pageInfo
                                    .endCursor,
                              },
                            });
                          }}
                          loading={loading}
                        >
                          Load more
                        </LoadingButton>
                      )}
                  </Stack>
                </SideDialogAccordion>
              );
            })}
          <SideDialogAccordion
            title="Packages to Collect from Concierge"
            startIcon={<Inventory fontSize="small" />}
          >
            <Stack gap={0.5}>
              {edgesToNodes(data?.home?.uncollectedPackages?.edges)
                .filter(p => p.pickupLocation === null)
                .map(node => {
                  return (
                    <PackageItem
                      id={node.id}
                      addressee={node.addressee || ''}
                      actionDateTime={DateTime.fromISO(node.createdAt)}
                      location={node.location ?? undefined}
                    />
                  );
                })}
              {edgesToNodes(data?.home?.uncollectedPackages?.edges).filter(
                p => p.pickupLocation === null,
              ).length === 0 && (
                <Stack alignItems="center">
                  <img
                    src={Package}
                    width="256px"
                    height="256px"
                    alt="package"
                    style={{opacity: 0.4}}
                  />
                  <Typography
                    variant="subtitle1"
                    color={palette.text.secondary}
                    mb={2}
                  >
                    No packages waiting for collection
                  </Typography>
                </Stack>
              )}
              {totalUncollectedCount > 0 &&
                data?.home?.uncollectedPackages?.pageInfo.hasNextPage && (
                  <LoadingButton
                    className={styles.loadingButton}
                    variant="arkOutline"
                    onClick={() => {
                      fetchMore({
                        variables: {
                          uncollectedAfter:
                            data?.home?.uncollectedPackages?.pageInfo.endCursor,
                        },
                      });
                    }}
                    loading={loading}
                  >
                    Load more
                  </LoadingButton>
                )}
            </Stack>
          </SideDialogAccordion>
          <SideDialogAccordion
            title="Collected Packages"
            startIcon={<Inventory fontSize="small" />}
            startCollapsed
          >
            <Stack gap={0.5}>
              {edgesToNodes(data?.home?.collectedPackages?.edges).map(node => {
                return (
                  <PackageItem
                    id={node.id}
                    addressee={node.addressee || ''}
                    actionDateTime={
                      node.collectedOn
                        ? DateTime.fromISO(node.collectedOn)
                        : undefined
                    }
                    location={node.location ?? undefined}
                  />
                );
              })}
              {data?.home?.collectedPackages?.edges.length === 0 && (
                <Typography variant="subtitle1">
                  No collected packages
                </Typography>
              )}
              {totalCollectedCount > 0 &&
                data?.home?.collectedPackages?.pageInfo.hasNextPage && (
                  <LoadingButton
                    className={styles.loadingButton}
                    variant="arkOutline"
                    onClick={() => {
                      fetchMore({
                        variables: {
                          collectedAfter:
                            data?.home?.collectedPackages?.pageInfo.endCursor,
                        },
                      });
                    }}
                    loading={loading}
                  >
                    Load more
                  </LoadingButton>
                )}
            </Stack>
          </SideDialogAccordion>
        </Stack>
      </Box>
      <Box
        position="absolute"
        width="100%"
        bottom={32}
        paddingRight={{xs: 0, md: 6}}
        display="flex"
        justifyContent="flex-end"
        gap={2}
        flexDirection={{xs: 'column', sm: 'row'}}
      >
        <LoadingButton
          variant="outlined"
          color="secondary"
          disabled={totalUncollectedCount === 0 || residentsReminded}
          loading={reminding}
          onClick={remindResidents}
        >
          Remind
        </LoadingButton>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setCollectAllDialogOpen(true);
            logEvent({
              name: 'Packages On Home Page - Collect All Button Clicked',
            });
          }}
          disabled={totalUncollectedCount === 0}
        >
          Mark All Collected
        </Button>
      </Box>
      <PackageDialog id={packageId} onClose={() => navigate(-1)} />
      <CollectAllPackagesDialog
        open={collectAllDialogOpen}
        onClose={() => setCollectAllDialogOpen(false)}
      />
    </PageContainer>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  loadingButton: {
    padding: theme.spacing(0.5, 4),
    alignSelf: 'center',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
