import React from 'react';
import {Box, BoxProps, Card, Typography} from '@mui/material';

export type EmptyListProps = {
  text?: string;
  children?: React.ReactNode;
} & BoxProps;

export const EmptyList = (props: EmptyListProps) => {
  const {sx, text, children, ...rest} = props;

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Card
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
          width: '100%',
          borderRadius: 2,
          backgroundColor: theme => theme.palette.background.paper,
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.01)',
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            opacity: 0.56,
            fontWeight: 500,
          }}
        >
          {text}
        </Typography>
        {children}
      </Card>
    </Box>
  );
};
